@import "_variables.scss";

/*@import "../../node_modules/sanitize.css/sanitize.css";*/
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

@import "_global.scss";
@import "_header.scss";
@import "_navbar.scss";
@import "_footer.scss";
@import "_brand.scss";
