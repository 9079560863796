/*!
 * Navigation | WEB
 */

.navbar {
    background-image: linear-gradient(#343a40,#000);
    margin-left: -15px;
    margin-right: -15px;
}

.navbar .nav-item.active {
    background-image: linear-gradient(#000,#343a40);
}

.dropdown-menu {
    background-color: #343a40;
}
a.dropdown-item {
    color: rgba(255,255,255,.5);
}

.navbar-nav .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.dropdown-menu {
    padding: 0.5rem;
}
.dropdown-item[href^="znackovaci-jazyk"].active,
.dropdown-item[href^="znackovaci-jazyk"]:active {
    background-color: $html;
}
.dropdown-item[href^="kaskadove-styly"].active,
.dropdown-item[href^="kaskadove-styly"]:active {
    background-color: $css;
}
.dropdown-item[href^="javascript"].active,
.dropdown-item[href^="javascript"]:active {
    background-color: $js;
}

@media (max-width: 767px) {
    [hidden].navbar-toggler {
        display: block !important;
    }
}
