.header {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    z-index: -1;
    background-color: black;
}
@media screen and (min-width: 576px) {
    .header {
        height: 150px;
    }
}

/*.page-html .header::before {
    background-image: url("../assets/image/resize/html-code-sm.jpg");
}

@media screen and (min-width: 768px) {
    .page-html .header::before {
        background-image: url("../assets/image/resize/html-code-md.jpg");
    }
}

@media screen and (min-width: 992px) {
    .page-html .header::before {
        background-image: url("../assets/image/resize/html-code-lg.jpg");
    }
}

@media screen and (min-width: 1200px) {
    .page-html .header::before {
        background-image: url("../assets/image/resize/html-code-xl.jpg");
    }
}*/
