/*!
 * Brand | WEB
 */

// Layout
.brand {
    margin: 0;
    display: flex;
    align-items: center;
}
.brand__logo {
    margin-right: 1em;
}
.brand__title {
    text-transform: uppercase;
}

// Footer
.footer .brand {
    margin-left: auto;
    margin-right: auto;
}
