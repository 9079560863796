/*!
 * Footer | WEB
 */

// Color
.footer {
    //background-color: #cccccc;
    //background-image: url("../assets/image/resize/pattern-stone-light-sm.jpg");
    background-color: #222222;
    background-image: url("../assets/image/resize/pattern-stone-dark-sm.jpg");
    & a {
        //color: #007bff;
        color: #ffffff;
    }
}

// Separator
.footer__separator {
    border-top: 4px solid #e34f26; // HTML
    position: relative;
}
.footer__separator::before,
.footer__separator::after {
    content: "";
    position: absolute;
    background-color: #0c73b8; // CSS
    height: 4px;
    width: 50%;
    top: -4px;
    left: 0;
}
.footer__separator::after {
    background-color: #e5a228; // JS
    width: 33%;
    left: 33%;
    transform:skewX(-60deg);
}

// Navigation
.footer__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;
}
.footer__nav > * {
    padding: 15px;
    min-width: 25%;
}
@media (max-width: 575px) {
    .footer__nav > * {
        min-width: 50%;
    }
}

// Info
.footer__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;
}
.footer__info > * {
    padding: 15px;
}
.brand {
    flex-grow: 1;
}
@media (max-width: 575px) {
    .brand {
        flex-grow: 0;
    }
}

// License
.license {
    margin: 0 auto;
    opacity: 0.7;
}
