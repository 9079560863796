/*!
 * Global | SWO
 */

html,
body {
    min-height: 100vh;
}
//.text-break (break long string)
body {
    word-break: break-word;
    overflow-wrap: break-word;
}
strong {
    font-weight: 600;
}
pre {
    padding: 0.5rem;
    background-color: #333333;
    color: #ffffff;
    border-radius: 0.25rem;
}
pre strong {
    color: $js;
}
table {
    margin-bottom: 1rem;
}
code {
    padding: 2px 4px;
    background-color: #f9f2f4;
    border-radius: 0.25rem;
}
iframe {
    margin-bottom: 0.5rem;
}
img.h1 {
    height: 1.2em;
    width: auto;
}
img.h1-lg {
    height: 2.15em;
}
main {
    overflow: hidden;
}

// Accessibility
main a,
aside a,
footer a {
    text-decoration: underline;
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
}

// Filter
.invert-0 {
    filter: invert(0);
}
.invert-20 {
    filter: invert(20%);
}
.invert-50 {
    filter: invert(50%);
}
.invert-80 {
    filter: invert(80%);
}
.invert-100 {
    filter: invert(100%);
}

.img-fluid {
    width: 100%; /* IE11 */
}
.jumbotron {
    margin-bottom: 1rem;
}
.figure-caption {
    text-align: center;
}
.r16by9,
.r16by10 {
    width: 100%;
    height: 0;
    padding-bottom: 56.3%;
}
.r16by10 {
    /* background-color: #eee; */
    padding-bottom: 62.5%;
}
.r16by9 .img-fluid,
.r16by10 .img-fluid {
    width: 100%;
    height: auto;
}

.header {
    color: #ffffff;
    text-shadow: 2px 2px 2px black;
}

// Card
.card-body :last-child {
    margin-bottom: 0;
}

// Layout
@media (max-width: 575.98px) {
    .navbar,
    .main {
        border-radius: 0 !important;
    }
}

// Aside
.db img {
    box-shadow:3px 3px 10px 5px #999;
    //vertical-align:top;
    transition:all .2s
}
.db a:hover img {
    box-shadow:2px 2px 6px 4px;
    transform:scale(.96)
}

// Decoration
.page-html {
    background-color: $html;
    background: linear-gradient(135deg, $html 20%, adjust-hue($html, 10%) 80%);
    a {
        color: $html;
    }
}
.page-css {
    background-color: $css;
    background: linear-gradient(135deg, $css 20%, adjust-hue($css, 10%) 80%);
    a {
        color: $css;
    }
}
.page-js {
    background-color: $js;
    background: linear-gradient(135deg, $js 20%, adjust-hue($js, 10%) 80%);
    a {
        color: darken($js, 15%);
    }
}

// Footable
.surname {
    background-color: #eee;
}

// Custom
@media (max-width: 575.98px) {
    .intro {
        min-height: 100vh;
    }
}
footer .small {
    line-height: 1.1;
}
